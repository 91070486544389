<template>
    <div class="card none-selectable" :class="{active: active}">
        <p class="sceme-index" :class="{active: active}">{{sceme.code}}</p>

        <!-- Схемы без химиотерапии -->
        <div v-if = "['sh0604','sh0709'].includes(sceme.code)">
            <div v-for="(part, partindex) in sceme.data" :key="partindex">
                <p class="parttitle">{{(partindex === 0) ? 'Фаза комбинации' : 'Фаза монотерапии**'}}</p>
                <div class="scemepart">
                    <svg width="20" :height="48">
                        <linearGradient :id="'points'+partindex+part.drugs.length" x1="0" y1="0" x2="1" y2="0">
                                <stop v-for="(drug, index) in part.drugs" :key="index"
                                    :offset="100/part.drugs.length/2 +100/part.drugs.length*index+'%'" :stop-color="drug.color"></stop>
                        </linearGradient>
                        <circle
                            cx="8" :cy="22" 
                            r="8" :fill="part.drugs[0].color" stroke-width="0"/>
                    </svg>

                    <div class="titles">
                        <div v-for="(drug, index) in part.drugs">
                            <p>
                                <span class="drugname" :style="{color:drug.color}">{{drug.name}}</span><br>
                                <span class="drugdose" v-html="drug.dose"></span>
                            </p>
                            <p v-if="index !== part.drugs.length-1">&nbsp;+&nbsp;</p>
                        </div>
                    </div>

                    <div class="loop">
                        <svg>
                            <linearGradient  v-for="n in part.drugs.length-1"
                                :key="n" 
                                :id="'circle'+partindex+(part.drugs.length)+n" 
                                :x1="0" :y1="0" :x2="0" :y2="1">
                                <stop offset="0%" :stop-color="part.drugs[part.drugs.length-n].color"></stop>
                                <stop offset="100%" :stop-color="part.drugs[part.drugs.length-n-1].color"></stop>
                            </linearGradient>
                            
                            <text x="17" y="26" class="looptxt" fill="#03417A" font-weight="600" font-size="18"> {{ part.loop }}</text>
                            <text x="13" y="37" class="looptxt" fill="#03417A" font-size="12">нед</text>
                            
                            <circle v-if="part.drugs.length===1" r="22" cx="24" cy="24" 
                            stroke-width="4" fill="none" :stroke="part.drugs[0].color"
                            stroke-dasharray="82 20 40"></circle>

                            <circle v-if="part.drugs.length===2" r="22" cx="24" cy="24" 
                            stroke-width="4" fill="none" :stroke="'url(#circle'+partindex+(part.drugs.length)+'1)'"
                            stroke-dasharray="82 20 40"></circle>

                            <g v-if="(part.drugs.length!==1) && (part.drugs.length!==2)" transform="translate(24,24)">
                                <path v-for="n in part.drugs.length" 
                                    :d="getSectorPath(0,0, 48, 135 + 315/(part.drugs.length)*(n-1), 135 + 315/(part.drugs.length)*n)" 
                                    fill="none" stroke-width="4" :stroke="part.drugs[part.drugs.length -n].color"
                                    />
                            </g>
                            <path d="M12.9695 4.70707C13.6846 4.54747 14.3229 5.18575 14.1633 5.90088L11.7358 16.7778C11.564 17.5476 10.6104 17.8248 10.0527 17.2671L1.6032 8.81766C1.04552 8.25998 1.32274 7.30636 2.09249 7.13457L12.9695 4.70707Z" 
                                :fill="part.drugs[part.drugs.length-1].color"/>
                            <text v-if="(part.cnt)&&
                                (part.drugs.length === 1)&&
                                (part.drugs[0].name==='Ниволумаб')&&
                                (partindex===1)" 
                            x="58" y="26" font-weight="600" fill="#03417A">до прогрессирования</text>
                            <text v-else x="58" y="26" font-weight="600" fill="#03417A">{{countStr(part.cnt)}}</text>
                        </svg>
                    </div>
                </div>
                <div class="pause" v-if="((part.drugs.length === 2)&&(part.drugs[0].name==='Ниволумаб')&&(part.drugs[1].name==='Ипилимумаб'))">
                    <svg width="20" height="130">
                        <line x1="8" y1="0" x2="8" y2="100%"
                            stroke-width="2" stroke="#afbabe" stroke-dasharray="6 6"/>
                    </svg>
                    <p class="titles"><span class="drugpause">далее через 6 недель*</span></p>
                </div>
            </div>
            <div>
                <p class="substr">* Интервал <b>6 недель</b> между последним введением комбинации Ниволумаб+Ипилимумаб и до первого введения Ниволумаб в дозе <b>480мг</b> (интервал <b>3 недели</b> до первого введения Ниволумаб  в дозе <b>240 мг</b> или <b>3мг/кг</b>) </p>
                <p class="substr">** в соотв. с инструкцией Ниволумаб может назначаться в альтернативных режимах дозирования: 3 мг/кг или 240 мг каждые 2 недели, 480 мг каждые 4 недели)</p>
            </div>
        </div>


        <!-- Схемы с химиотерапией -->
        <div v-if = "['sh1061','sh1062','sh1063'].includes(sceme.code)">
            <div class="scemepart himio" style="padding: 0 0 25px 10px;">
                <p class="day1">1 день</p>
                <svg width="20" :height="48">
                    <circle
                        cx="8" cy="22" 
                        r="8" fill="#03417A" stroke-width="0"/>
                </svg>
                <div class="titles">
                    <div>
                        <p>
                            <span class="drugname" style="color:#03417A">Ниволумаб</span><br>
                            <span class="drugdose">360мг</span>
                        </p>
                        <p>&nbsp;+&nbsp;</p>
                        <p>
                            <span class="drugname" style="color:#00C013">Ипилимумаб</span><br>
                            <span class="drugdose">1 мг/кг</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="pause himio">
                    <svg width="20" height="90">
                        <line x1="8" y1="0" x2="8" y2="100%"
                            stroke-width="2" stroke="#afbabe" stroke-dasharray="6 6"/>
                    </svg>
                    <p class="titles"><span class="drugpause himio">через 3 недели</span></p>
            </div>
            <div class="scemepart himio">
                <svg width="20" :height="48">
                    <circle
                        cx="8" cy="22" 
                        r="8" fill="#0084BD" stroke-width="0"/>
                </svg>
                <div class="titles">
                    <div>
                        <p>
                            <span class="drugname" style="color:#0084BD">Ниволумаб</span><br>
                            <span class="drugdose">360мг</span>
                        </p>
                    </div>
                </div>
            </div>
            <p class="day21">21<br>день</p>
            <p class="cycle">цикл</p>
            <svg class="groupsymbol">
                <text x="0" y="28" transform="scale(1.2, 4)" fill="#aaaaaa" font-size="30">}</text>
            </svg>

            <svg class="groupsceme">
                <linearGradient 
                                :id="'circlehimio'" 
                                :x1="0" :y1="0" :x2="0" :y2="1">
                                <stop offset="0%" stop-color="#00C013"></stop>
                                <stop offset="100%" stop-color="#03417A"></stop>
                </linearGradient>
                <circle r="22" cx="24" cy="24" 
                        stroke-width="4" fill="none" :stroke="'url(#circlehimio)'"
                        stroke-dasharray="82 20 40"></circle>
                <path d="M12.9695 4.70707C13.6846 4.54747 14.3229 5.18575 14.1633 5.90088L11.7358 16.7778C11.564 17.5476 10.6104 17.8248 10.0527 17.2671L1.6032 8.81766C1.04552 8.25998 1.32274 7.30636 2.09249 7.13457L12.9695 4.70707Z" 
                                fill="#00C013"/>            
                <text x="17" y="26" class="looptxt" fill="#03417A" font-weight="600" font-size="18"> 6</text>
                <text x="13" y="37" class="looptxt" fill="#03417A" font-size="12">нед</text>
                <text x="58" y="26" font-weight="600" fill="#03417A">x 9 повторений</text>
            </svg>


            <p class="himiosubstr">В первый цикл терапии в 1й и 21й дни проводится Химиотерапия</p>
            <div class="scemepart">
                <svg width="20" :height="48">
                    <circle
                        cx="8" :cy="22" 
                        r="8" :fill="sceme.data[2].drugs[0].color" stroke-width="0"/>
                </svg>
                <div class="titles">
                    <div v-for="(drug, index) in sceme.data[2].drugs">
                        <p>
                            <span class="drugname" :style="{color:drug.color}">{{drug.name}}</span><br>
                            <span class="drugdose" v-html="drug.dose"></span>
                        </p>
                        <p v-if="index !== sceme.data[2].drugs.length-1">&nbsp;+&nbsp;</p>
                    </div>
                </div>
                <div class="loop">
                    <svg>
                        <text x="17" y="26" class="looptxt" fill="#03417A" font-weight="600" font-size="18"> {{ sceme.data[2].loop }}</text>
                        <text x="13" y="37" class="looptxt" fill="#03417A" font-size="12">нед</text>
                        <circle r="22" cx="24" cy="24" 
                            stroke-width="4" fill="none" :stroke="sceme.data[2].drugs[0].color"
                            stroke-dasharray="82 20 40"></circle>
                        <path d="M12.9695 4.70707C13.6846 4.54747 14.3229 5.18575 14.1633 5.90088L11.7358 16.7778C11.564 17.5476 10.6104 17.8248 10.0527 17.2671L1.6032 8.81766C1.04552 8.25998 1.32274 7.30636 2.09249 7.13457L12.9695 4.70707Z" 
                                :fill="sceme.data[2].drugs[0].color"/>
                        <text x="58" y="26" font-weight="600" fill="#03417A">x 2 инфузии</text>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Sceme',
  props:{
    active: Boolean,
    sceme: Object
  },
  methods:{
    countStr: function(num) {

        let res = 'x ', strEnd = ' раз', val;

        res = res + num;
        if(num>4 && num<21) {
            res = res + strEnd;    
            return res;
        }
        val = num%10;
        if(val>1 && val<5) strEnd = ' раза';
        res = res + strEnd;    
        return res;
    },
    getSectorPath:function(x, y, outerDiameter, a1, a2) {
        let degtorad = Math.PI / 180;
        let halfOuterDiameter = outerDiameter / 2;
        let cr = halfOuterDiameter - 3;
        let cx1 = (Math.cos(degtorad * a2) * cr) + x;
        let cy1 = (-Math.sin(degtorad * a2) * cr) + y;
        let cx2 = (Math.cos(degtorad * a1) * cr) + x;
        let cy2 = (-Math.sin(degtorad * a1) * cr) + y;

        return "M" + cx1 + " " + cy1 + " A" + cr + " " + cr + " 0 0 1 " + cx2 + " " + cy2;
    },
    getGradX:function(cnt, num){
        let sector=315/cnt;
        let angle=135+sector*(num-1);
        let x=Math.cos(Math.PI/180*angle)/2+0.5;
        return x;
    },
    getGradY:function(cnt, num){
        let sector=315/cnt;
        let angle=135+sector*(num-1);
        let y=Math.sin(Math.PI/180*angle)/2+0.5;
        return y;
    },
    getGradXStart:function(cnt, num){
        let sector=315/cnt;
        let angle=135+sector*num;
        let x=Math.cos(Math.PI/180*angle)/2+0.5;
        return x;
    },
    getGradYStart:function(cnt, num){
        let sector=315/cnt;
        let angle=135+sector*num;
        let y=Math.sin(Math.PI/180*angle)/2+0.5;
        return y;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.card{
    background: rgba(255, 255, 255, 0.2);
    border: 5px solid #D4E6EC;
    padding: 5px 15px 5px 15px;
    margin: 10px;
    width:440px;
    cursor:pointer;
}
.card.active{
    background: rgba(255, 255, 255, 0.5);
}
.scemepart, .pause{
  display: flex;
  align-items: center;
  background: #ffffff59;
  padding: 0 10px;
  margin: 10px 0;
}
.scemepart.himio{
    margin: 15px 0 0 0;
}
.scemepart svg{
    flex: none;
    z-index:10;
    position:relative;
}
.sceme-index{
    margin: 10px 0 0 0;
    color: #e6f8fe;
}
.sceme-index.active{
    color: #949c9f;
}
.pause{
    margin:-44px 0;
    padding: 6px 10px;
    background: transparent;
}
.pause svg{
    position:relative;
    z-index:0;
    margin-bottom: -32px;
}
.pause.himio svg{
    margin-bottom: 0;
}
.pause.himio{
    margin: -71px 0;
    padding: 0px 10px;
}
.drugname, .drugpause{
  font-weight: 600;
  color:#787572;
  margin: 5px 0px;
}
.groupsceme{
position: absolute;
    margin-top: -101px;
    margin-left: 255px;
    width: 173px;
    height: 50px;
}
.drugpause{
  font-weight: 300;
  font-size: 14px;
}
.drugpause.himio{
    margin: 32px 0 46px 0;    
}
.drugdose{
  margin: 5px 0px;
  color:#787572;
}
.loop svg{
    width: 100%;
    height: 48px;
}
.loopsub{
    font-size:10px;
}
.titles{
    padding: 0 10px;
    /*width: 220px;*/
    padding: 0 20px 0 10px;
    display:flex;
}
.titles div{
    display:flex;
}
.substr{
  width:440px;
  text-align: justify;
  font-size: 13px;
  color: #747474;
  margin-top: 5px;
}
.himiosubstr{
    background: #ffffff59;
    width: 420px;
    text-align: center;
    font-size: 13px;
    color: #747474;
    margin: 10px 0px -10px 0px;
    padding: 10px 10px 0px 10px
}
.parttitle{
    background: #ffffff59;
    margin: 10px 0 -10px 0px;
    padding: 10px 0 0 0;
    text-align: center;
    color: #555555;
}
.day1{
    font-weight:300;
    font-size:9px;
    color:#03417A;
    position:absolute;
    margin: -36px 0 0 -5px;
}
.day21{
    font-weight:300;
    font-size:9px;
    color: #0084bd;
    position: absolute;
    margin: -26px 0 0 9px;
    text-align: center;
}
.cycle{
    font-weight: 300;
    font-size: 14px;
    color: #00C013;
    position: absolute;
    margin: -120px 0 0 260px;
    text-align: center;
}
.groupsymbol{
    position: absolute;
    height: 135px;
    margin: -149px 0px 0px 240px;
    width: 13px;
}
</style>
import { render, staticRenderFns } from "./ScemeStatic.vue?vue&type=template&id=4a9a6e85&scoped=true&"
import script from "./ScemeStatic.vue?vue&type=script&lang=js&"
export * from "./ScemeStatic.vue?vue&type=script&lang=js&"
import style0 from "./ScemeStatic.vue?vue&type=style&index=0&id=4a9a6e85&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a9a6e85",
  null
  
)

export default component.exports
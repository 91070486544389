<template>
  <div>
    <h1>Выбор схем терапии</h1>
    <div class="scemesbox">
      <ScemeStatic v-for="(sceme, index) in scemes" 
            :key="index" 
            v-on:click.native="scemeSelect(index)" 
            :active="index === curSceme" 
            :sceme="sceme"/>
    </div>
    <router-link
      to="/singlecalc"
      class="next_btn">
      Рассчитать
    </router-link>
    <!--<p class="substr" v-if="flRemark">* Интервал между последним введением комбинации Ниволумаб+Ипилимумаб и до первого введения Ниволумаб 480мг</p>-->
  </div>
</template>

<script>
import ScemeStatic from '@/components/ScemeStatic.vue'
import dataStore from '@/views/data'
import methodSet from '@/views/schemas'

export default {
  components:{
    ScemeStatic
  },
  data:function(){
    return {
      curSceme: 0,
      currSet: [],
      nivo_1mg: {name:'Ниволумаб', dose:'1 мг/кг', color:'#03417A'},
      nivo_3mg: {name:'Ниволумаб', dose:'3 мг/кг', color:'#03417A'},
      ipili_1mg: {name:'Ипилимумаб', dose:'1 мг/кг', color:'#00C013'},
      ipili_3mg: {name:'Ипилимумаб', dose:'3 мг/кг', color:'#00C013'},
      nivo_360mg: {name:'Ниволумаб', dose:'480 мг', color:'#0084BD'},
      nivo_480mg: {name:'Ниволумаб', dose:'480 мг', color:'#0084BD'},
      pacli_200mg: {name:'Паклитаксел', dose:'200 мг/м<sup>2</sup>', color:'#787572'},
      carbo_130mg: {name:'Карбоплатин', dose:'AUC6', color:'#787572'},
      pemetr_500mg: {name:'Пеметрексед', dose:'500 мг/м<sup>2</sup>', color:'#787572'},
      cisplat_75mg: {name:'Цисплатин', dose:'75 мг/м<sup>2</sup>', color:'#787572'}
    }
  },
  computed:{

    flRemark: function() {

      let flRemark = true, indic;

      indic = dataStore.currIndic;
      if(indic>3 && indic<6) flRemark = false;

      return flRemark;
    },

    scemes:function(){

      const methData = 
      [
        {
          code:'sh0604',
          data:[
            { drugs:[this.nivo_1mg, this.ipili_3mg], cnt:4, loop: 3 }, 
            { drugs:[this.nivo_480mg], cnt:10, loop: 4}
          ]
        },
        {
          code:'sh0709',
          data:[
            { drugs:[this.nivo_3mg, this.ipili_1mg], cnt:4, loop: 3 }, 
            { drugs:[this.nivo_480mg],  cnt:10, loop: 4}
          ]
        },
        { 
          code:'sh1063',
          data:[
            { drugs:[this.nivo_360mg], cnt:18, loop: 3 }, 
            { drugs:[this.ipili_1mg], cnt:9, loop: 6}, 
            { drugs:[this.pacli_200mg, this.carbo_130mg], cnt:2, loop: 3}
          ]
        },
        {
          code:'sh1061',
          data:[
            { drugs:[this.nivo_360mg], cnt:18, loop: 3 }, 
            { drugs:[this.ipili_1mg], cnt:9, loop: 6}, 
            { drugs:[this.pemetr_500mg, this.carbo_130mg], cnt:2, loop: 3}
          ]
        },
        {
          code:'sh1062',
          data:[
            { drugs:[this.nivo_360mg], cnt:18, loop: 3 }, 
            { drugs:[this.ipili_1mg], cnt:9, loop: 6}, 
            { drugs:[this.pemetr_500mg, this.cisplat_75mg], cnt:2, loop: 3}
          ]
        }
      ];
      let methSet = dataStore.pathMethods[dataStore.currIndic], res = [];
      this.currSet = []; 
      for(let cnt=0, point=0, len=methSet.length ; cnt<len ; cnt++) {
        if(methSet[cnt]) { 
          res.push(methData[cnt]);
          this.currSet.push(cnt);
          if(cnt==dataStore.currMethod-1) {
            this.curSceme = point;
          }
          point++;
        }
      }
      return res;
    }
  },
  methods:{
    scemeSelect(id){
      this.curSceme = id;
      dataStore.currMethod = this.currSet[id] + 1;
    }
  },
  mounted() {
    this.curSceme = dataStore.currMethod - 1;
  }  
}
</script>
<style scoped>
h1{
  margin: 10px 0 20px 0;
}
.scemesbox{
  display:flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.next_btn{
  margin: auto;
  margin-top: 15px;
  display: block;
  width: 200px;
  background-color: #03417A;
  text-decoration: none;
  color: #fff;
  font-family: 'Roboto';
  text-align: center;
  padding: 12px;
}
.substr{
  text-align: center;
  font-size: 13px;
  color: #747474;
  margin-top: 30px;
}
</style>